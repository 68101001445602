
import { defineComponent, ref, onMounted } from "vue";
import { Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "financialResolution",
  components: {
    Field,
    Form,
  },
  props: ["customer"],
  async mounted() {
    this.init();
  },
  data() {
    const schema = Yup.object({});
    const financial_resolution = {
      customer_details: {
        name: this.customer.name,
        ic_number: this.customer.nric,
        age: 0,
        contact_no: this.customer.phone,
        marital_status: this.customer.detail.marital_status,
        dmp_reference_number: this.customer.detail.dmp_reference_number,
        dependents: this.customer.detail.no_of_dependant,
        employer_name: "-",
        employment_type: this.customer.detail.employment_status,
        epf_account_2_balance: 1,
        email: this.customer.email,
        customer_s_address:
          this.customer.detail.city + "," + this.customer.detail.state,
        postcode: "-",
        reason_for_defaul: "-",
      },
      net_worth_secured: {
        liabilities: [
          {
            facility_type: "Home Financing",
            asset: 270000,
            liabilities: 233323.75,
            net_worth: 36676.25,
          },
          {
            facility_type: "Home Financing",
            asset: 0,
            liabilities: 919018.71,
            net_worth: -919018.71,
          },
          {
            facility_type: "Home Financing",
            asset: 0,
            liabilities: 902022.57,
            net_worth: -902022.57,
          },
        ],
        total: {
          asset: 270000,
          liabilities: 2054365.03,
          net_worth: -1784365.03,
        },
      },
      net_worth_unsecured: {
        liabilities: [
          {
            facility_type: "Personal Loan",
            asset: 0,
            liabilities: 65167.61,
            net_worth: -65167.61,
          },
          {
            facility_type: "Personal Financing",
            asset: 0,
            liabilities: 6007.26,
            net_worth: -6007.26,
          },
          {
            facility_type: "Term Loan Financing",
            asset: 950000,
            liabilities: 1931718.71,
            net_worth: -981718.71,
          },
        ],
        total: {
          asset: 950000,
          liabilities: 2193160.74,
          net_worth: -1243160.74,
        },
      },
      
      crdtr_infrmtn_incm_expnss: {
        ttl_grss_incm: 15000,
        stry_ddctns: 0,
        fxd_instlmnt_nt_in_dmp: 0,
        ttl_hshld_expnss: 0,
        nt_dispsbl_incm: 0,
      },
      crdtr_infrmtn_dtls: {
        scrd_loan: [],
        unscrd_loan: [],
      },
     
    };

    const fcltyTyp = ref({
      value: 0,
      options: [],
    });

    const loanTypes = ref({
      value: ["Secured", "Unsecured"],
      options: ["Secured", "Unsecured"],
    });

    const installmentMethods = ref({
      value: ["Single"],
      options: ["Single", "StepUp"],
    });

     const forApprovalData = {
      certification: "",
      approvingLevel:"",
    };

    const businessSectors = [
      {
        value: "Agriculture",
        label: "Agriculture",
      },
      {
        value: "Construction",
        label: "Construction",
      },
      {
        value: "Manufacturing",
        label: "Manufacturing",
      },
      {
        value: "Services",
        label: "Services",
      },
      {
        value: "Trading",
        label: "Trading",
      },
    ];

    const businessTypes = [
      {
        value: "Sole Prop",
        label: "Sole Prop",
      },
      {
        value: "Partnership",
        label: "Partnership",
      },
      {
        value: "Sdn Bhd",
        label: "Sdn Bhd",
      },
    ];

    const businessOperations = [
      {
        value: "Permanently ceased operation",
        label: "Permanently ceased operation",
      },
      {
        value: "Temporarily ceased operation",
        label: "Temporarily ceased operation",
      },
      {
        value: "70% or more reduction in cash inflow / sales",
        label: "70% or more reduction in cash inflow / sales",
      },
      {
        value: "30% to less than 70% reduction in cash inflow / sales",
        label: "30% to less than 70% reduction in cash inflow / sales",
      },
      {
        value: "less than 30% reduction in cash inflow / sales",
        label: "less than 30% reduction in cash inflow / sales",
      },
    ];

    const store = useStore();
    const route = useRoute();

    return {
      store,
      route,
      fcltyTyp,
      loanTypes,
      installmentMethods,
      schema: schema,
      cstmr_dtls: financial_resolution.customer_details,
      nt_wrth_scrd: financial_resolution.net_worth_secured,
      nt_wrth_unscrd: financial_resolution.net_worth_unsecured,
      crdtr_infrmtn_incm_expnss: financial_resolution.crdtr_infrmtn_incm_expnss,
      crdtr_infrmtn_dtls: financial_resolution.crdtr_infrmtn_dtls,
      
      forApprovalData,
      businessSectors,
      businessTypes,
      businessOperations
    };
  },
  setup() {
    const { t, te, n } = useI18n();
    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const nFrmtThsnd = (data) => {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const forApprovalFormValidator = Yup.object({
      forApprovalData: Yup.object({
        certification: Yup.string().required().label("Certification"),
        approvingLevel: Yup.string().required().label("Approving Level"),
      }),
    });

    return {
      nFormat,
      nFrmtThsnd,
      forApprovalFormValidator
    };
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("Financial Resolution Proposal Plan (SDRS)", []);
      
      this.store.dispatch(Actions.GET_FACILITIES).then(() => {
        const cbsFacilities = this.store.getters.getFacilitiesData;

        this.fcltyTyp.options = cbsFacilities;
      });
    },
 
    forApprovalFormSubmit(v) {
      console.log(v);
    },
  },
});
