
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import CheckList from "../sdrsPlan/CheckList.vue";
import ForApprovalForm from "../sdrsPlan/ForApproval.vue";
import CreditReport from "../ccris/CreditReport.vue";
import FinancialResolution from "../financialResolution/FinancialResolution.vue";
// import ProfilingQuestionnaire from "../profilingQuestionnaire/ProfilingQuestionnaire.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElLoading } from "element-plus";
// import upload from "element-plus/es/components/upload/src/ajax";
// import { Action } from "vuex-module-decorators";
// import { ElLoading } from "element-plus";

export default defineComponent({
  name: "customer-financial-statements",
  components: {
    CheckList,
    ForApprovalForm,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Financial Resolution", ["Customer"]);
    });

    return {};
  },
  data() {
    // console.log();
    // const store = useStore();
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const ocr = {
      inputConfig: {
        contents: "",
        mimeType: "",
      },
      tableExtractionParams: {
        enabled: true,
        modelVersion: "builtin/stable",
      },
    };

    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };
    return {
      store: useStore(),
      cstmr_id,
      customer,
      ocr,
      isCstmrDtRdy: false,
    };
  },
  methods: {
    init() {
      // this.store.dispatch(Actions.GET_ACCESS_TOKEN);
      this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
        this.customer = this.store.getters.getCustomerData;
        this.isCstmrDtRdy = true;
      });
    },
    handleRemove(file, uploadFiles) {
      console.log(file, uploadFiles);
    },
    handleChange(uploadFile, uploadFiles) {
      console.log(uploadFile, uploadFiles);
      const reader = new FileReader();
      // const that = this;
      const file = uploadFile.raw;

      this.ocr.inputConfig.mimeType = file.type;
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.ocr.inputConfig.contents = reader.result
          ? (reader.result as string).replace("data:", "").replace(/^.+,/, "")
          : "";

        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        this.store.dispatch(Actions.GET_OCR, this.ocr).then(() => {
          // const data = this.store.getters.getOcrData;
          // const form = this.$refs.form as any;

          // form.setFieldValue("income.amount", data.amount);
          // form.setFieldValue("income.paid_at", data.date);
          loading.close();
        });
      };

      reader.readAsDataURL(file);
      // const size = file.size / 1000000;

      // new Compressor(file, {
      //   quality: size > 1 ? 0 : 1,

      //   // The compression process is asynchronous,
      //   // which means you have to access the `result` in the `success` hook function.
      //   success(result) {
      //     reader.readAsDataURL(result);
      //     // that.image.url = URL.createObjectURL(result);
      //   },
      //   error(err) {
      //     console.log(err.message);
      //   },
      // });
    },
    handlePreview(uploadFile) {
      console.log(uploadFile);
    },
    beforeRemove(uploadFile, uploadFiles) {
      console.log(uploadFile, uploadFiles);
      Swal.fire({
        title: "Congratulation!",
        text: "Are you sure to delete this item?.",
        icon: "info",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        //cancelButtonText: 'No, I am not!',
        confirmButtonText: "OK",
      }).then(
        () => true,
        () => false
      );
    },
  },
  async mounted() {
    this.init();
  },
});
