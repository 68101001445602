
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  name: "invite-friends-modal",
  components: { Form, Field, ErrorMessage },
  setup() {
    const checkListFormValidator = Yup.object({
      checkListData: Yup.object({
        certification: Yup.string().required().label("Certification"),
      }),
    });
   

    const m1 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const m2 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const m3 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const m4 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

     const a1 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const a2 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const a3 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const a4 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const e1 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const e2 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const e3 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const e4 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

     const e5 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
      { value: "Not applicable", label: "Not applicable"}
    ];

    const e6 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const e7 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const e8 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const e9 = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    return {
      checkListFormValidator,
      m1,
      m2,
      m3,
      m4,
      a1,
      a2,
      a3,
      a4,
      e1,
      e2,
      e3,
      e4,
      e5,
      e6,
      e7,
      e8,
      e9  
    };
  },
  data() {
    const checkListData = {
      certification: "",
    };

    return {
      checkListData,
    };
  },
  methods: {
    checkListFormSubmit(v) {
      console.log(v);
    },
  },
});
